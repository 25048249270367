//
// Style by region
// ============================================================================

// -- Content region --

.region-content-prefix {
  .block {
    margin: 0 auto;
    margin-bottom: 40px;
    max-width: @container-max-width;
    // > .block-title {
    //   font-size: 30px;
    //   margin-bottom: 55px;
    //   // letter-spacing: 0.05em;
    //   text-align: center;
    //   text-transform: uppercase;
    // }

    // &:last-child { margin-bottom: 0; }
  }
}

.region-content-suffix,
.region-content {

  .block {
    margin: 0 auto;
    margin-bottom: 40px;
    max-width: @container-max-width;
    > .block-title {
      font-size: 30px;
      margin-bottom: 55px;
      // letter-spacing: 0.05em;
      text-align: center;
      text-transform: uppercase;
    }

    &:last-child { margin-bottom: 0; }
  }
}

.page-node-messages,
.page-node-updates,
.node-type-project,
.page-node-supporters {
  .region-content-suffix,
  .region-content {

    .block {
      margin: 0 auto;
      margin-bottom: 20px;
      max-width: @container-max-width;
      > .block-title {
        font-size: 30px;
        margin-bottom: 55px;
        // letter-spacing: 0.05em;
        text-align: left;
        text-transform: uppercase;
        &:after {
          background: #4a4a4a;
          content: '';
          display: block;
          height: 1px;
          margin: 15px 0 40px 0;
          width: 100px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.node-type-support-departments-faculties,
.page-faculties-and-departments {

  .region-content-suffix,
  .region-content {
    .block {
      margin: 0 auto;
      margin-bottom: 0;
    }
  }
}


// -- Sidebar --
.region-sidebar-second {

  // > .block:not(.block-menu-block) {
  //   margin-top: 60px;
  // }

  .block {
    font-size: 18px;
    margin: 0 0 30px;
    padding-top: 15px;

    > .block-title {
      font-size: 22px;
      margin-bottom: 15px;
      margin-top: 0px;
      // letter-spacing: 0.05em;
      text-transform: uppercase;
    }
  }

  .sidebar-gray {
    background-color: #F0F0F0;
    border-top: 7px solid #E8E8E8;
    padding: 25px 20px;

    h3 {
      margin: 0 0 10px;
    }
    .node-webform {
      h3 {
        color: @red;
        .fz-responsive(36px, 1);
      }
    }

    &.faq-block,
    &.fund-tips-block {
      display: flex;
      flex-direction:row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: nowrap;
      width: 100%;

      .icon {
        flex: 0 0 50px;

      }
      .text {
        flex: 1 1 auto;
        margin-left: 5px;

        .subtitle {
          .fz-responsive(16px);
        }
      }
    }

    &.future-legacy-block {

      .title-wrapper {
        display: flex;
        flex-direction:row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
        margin-bottom: 10px;

        .icon {
          flex: 0 0 60px;
          margin-right: 10px;
        }
        .text {
          flex: 1 1 auto;
          margin-left: 5px;

          .subtitle {
            .fz-responsive(16px);
          }
        }
      }

    }

    .block-webform {
      h2.block-title {
        display: none;
        margin-top: 0;
      }
    }

    form.webform-client-form {
      > div {
        display: flex;
        flex-direction:row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
        .form-item {
          flex: 1 1 auto;
          display: block;
          width: 100%;

          .form-control {
            width: 100%;
          }
        }

        .form-actions {
          flex: 0 0 112px;
          display: block;
          width: 100%;

        }
      }
    }
  }
}

.page-node-messages,
.page-node-updates,
.node-type-project,
.page-node-supporters {
  .region-content {
    padding-bottom: 0px;

    @media (min-width: @screen-sm-min) {
      padding-bottom: 40px;
    }
  }
  .region-sidebar-second {
    display: flex;
    flex-direction: column;
    .block {
      border-top: 0px;
    }
  }
}

.node-type-support-departments-faculties {
  .region-content {
    padding-bottom: 0px;
  }
  .region-sidebar-second {

  }
}

// -- Footer region --
.region-footer {

  .block { margin-bottom: 30px; }

  .block-webform {

    > .block-title {
      font-size: 22px;
      // letter-spacing: 0.05em;
      text-transform: uppercase;
    }

    .webform-component-email {
      background: @gray-lighter;
    }

    .email {
      background: @gray-lighter;
      border: 0 none;
      // height: @height;
    }

    .btn {
      @media (min-width: @screen-lg-min) {
        left: -0.3em;
        position: relative;
      }
      &:hover {
        background: @blue-medium;
        border-color: @blue-medium;
        color:white;
      }
    }
  }
}

//
// Individual blocks
// ============================================================================

// -- Support a Student --
#block-give-sfu-base-support-student {
  background: @brand-primary;
  color: #fff;
  font-size: @font-size-h5;
  padding: 50px 30px;
  position: relative;
  text-align: center;
  > .block-title {
    font-size: 35px;
    line-height: 1em;
    letter-spacing: normal;
    margin: 0px auto 30px auto;

    @media (min-width: @screen-sm-min) {
      font-size: 40px;
    }
    @media (min-width: @screen-md-min) {
      font-size: @font-size-big;
    }
  }

  > .block-content {

    &::before,
    &::after {
      background: @brand-primary;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      right: 100%;
      top: 0;
      width: 50%;
    }

    &::after {
      left: 100%;
      right: auto;
    }
  }

  .btn-primary {
    background: @red-light;
    border-color: @red-light;
    box-shadow: 1px 2px 26px -3px #333;
    margin-left: 8px;
    .fz-responsive(40px);

    &:hover, &:focus {
      background: @blue-medium;
      border-color: @blue-medium;
    }
  }

  p { margin-bottom: 1.8em; }

  .more {
    color: #fff;
    font-family: 'dinot-cond';
    font-weight: bold;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    &:hover, &:focus { text-decoration: underline; }

    .glyphicon {
      font-size: 0.7em;
      opacity: 0.5;
    }

    body.not-front & { display: none; }
  }
}

// -- Sign Up for Our Newsletter (Big) --
#block-give-sfu-base-newsletter-signup-big {
  background: @brand-primary;
  padding: 60px 30px;
  position: relative;
  .inline.left {
        padding-right: 50px;
  }
  .inline.right {
    h3 {
          padding-right: 20px;
          margin: 0 0 10px 0;
    }
  }
  .inline {
    display:inline-block;
    vertical-align: middle;
  }
  > .block-title {
    font-size: 35px;
    line-height: 1em;
    letter-spacing: normal;
    margin: 0px auto 30px auto;
  }

  > .block-content {
    color: #fff;
    font-size: 20px;
    line-height: 1.2em;
    text-align: center;

    &::before,
    &::after {
      background: @brand-primary;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      right: 100%;
      top: 0;
      width: 50%;
    }

    &::after {
      left: 100%;
      right: auto;
    }

    h3 { margin: 0 0 30px 0; }

    a {
      color: #FFF;
      text-decoration: underline;

      &:hover, &:focus { text-decoration: none; }
    }
  }

  form {
    margin: 30px auto 0 auto;
    position: relative;
  }

  .form-text {
    border-color: #FFF;
    border-radius: 0;
  }

  .form-submit {
    background: @red-light;
    border-color: @red-light;
    width: 60%;
    box-shadow: 1px 2px 26px -3px #333;

    &:hover, &:focus {
      background: @blue-medium;
      border-color: @blue-medium;
    }

    .glyphicon { top: 3px; }
  }

  p { margin-bottom: 1.8em; }

  @media (max-width: (@screen-xs-min - 1)) {

    .pull-left { float:  none !important; }
  }

  @media (min-width: @screen-xs-min ) {

    > .block-content { text-align: left; }

    .pull-left { margin-right: 20px; }

    .webform-component-email { width: 65%; }

    .form-actions { width: 33%; }

    .form-text {
      height: 50px;
      font-size: 19px;
      width: 100%;
      padding: 0.25em 0.5em;
    }

    .form-submit {
      font-size: 20px;
      line-height: 1em;
      height: 50px;
      padding: 0.25em 0;
      width: 100%;
    }
  }

  @media (min-width: @screen-xs-min ) and (max-width: (@screen-sm-min - 1)) {

    .form-item,
    .form-actions {
      clear: none;
      float: left;
    }
  }

  @media (min-width: @screen-sm-min) {

    > .block-title {
      font-size: 40px;
      line-height: 1.2em;
    }

    form {
      left: 5px;
      width: percentage((10 / @grid-columns));
    }

    .webform-component-email { width: 66%; }

    .form-actions { width: 32%; }

    .form-text {
      height: 70px;
      padding: 0.25em 1.75em;
    }

    .form-submit {
      .fz-responsive(40px);
      line-height: 1em;
      height: 70px;

      .glyphicon {
        font-size: 0.9em;
        top: 4px;
      }
    }
  }

  @media (min-width: @screen-md-min) {

    > .block-title { font-size: @font-size-big; }

    > .block-content {
      font-size: @font-size-h5;
    }

    form {
      width: percentage((8 / @grid-columns));
    }
  }

  @media (min-width: @screen-lg-min) {

    form {
      width: percentage((6 / @grid-columns));
    }

    .webform-component-email { width: 60%;      padding-right: 8px;
 }

    .form-actions { width: 38%; }
  }
}

// -- Ways to Give --
#block-give-sfu-base-ways-to-give {

  .block-title {
    margin-top: 0px;
  }
  margin-bottom: 17px;
  .block-content {

    > .row {
      margin-bottom: 56px;
     }
    .row:nth-child(2n) {
      margin-bottom: 0px;
    }
  }

  .giving-vehicles {

    margin-bottom: 0;
     li {
       a {
         font-weight: bold;
       }
     }
   }

  .donation-ways {
    padding-left: 0;

    li {
      background: #EEF0F0;
      border: 1px solid #E8E8E8;
      list-style: none outside none;
      padding: 40px 20px;

      h3 {
        margin: 0 0 1em 0;
      }

      .content {
        font-size: 20px;
        line-height: 1.2em;
      }
    }
  }

  @media (min-width: @screen-sm-min) {

    .donation-ways {

      li {
        padding: 40px 0;
        position: relative;

        &::before {
          background-position: center top;
          background-repeat: no-repeat;
          content: '';
          height: 72px;
          left: 0px;
          position: absolute;
          top: 40px;
          width: percentage((2 / @grid-columns));
        }

        h3,
        .content {
          margin-left: percentage((2 / @grid-columns));
          margin-right: percentage((1 / @grid-columns));
        }
      }
    }

    #donation-cheque::before {
      background-image: url(../images/icon_cheque_2x.png);
      background-size: 92px 52px;
    }

    #donation-online::before {
      background-image: url(../images/icon_online_2x.png);
      background-size: 92px 66px;
    }

    #donation-monthly::before {
      background-image: url(../images/icon_monthly_2x.png);
      background-size: 66px 67px;
    }

    #donation-gift-estate-planning::before {
      background-image: url(../images/icon_giftsandestate_2x.png);
      background-size: 76px 72px;
    }

    #donation-tribute-memorial::before {
      background-image: url(../images/icon_tribute_2x.png);
      background-size: 95px 72px;
    }

    #donation-git-services::before {
      background-image: url(../images/icon_inkind_2x.png);
      background-size: 72px 72px;
    }

    #donation-payroll-deduction::before {
      background-image: url(../images/icon_payroll_2x.png);
      background-size: 92px 57px;
    }
  }
}

#block-webform-client-block-290 {
  .block-title {
    font-size: 18px;
  }
} //block-webform-client-block-290

// -- Connect --
#block-block-3 {

  .block-title { margin-bottom: 30px; }

  td { border: 0 none; }
}

// -- Search Form --
#block-search-form {
  position: relative;

  .contextual-links-wrapper { display: none !important; }

  .input-group {
    float: left;
    width: 100%;
  }

  .input-group-btn { display: none; }

  .form-text {
    border-radius: 0;
    font-size: 18px;
    // height: 40px;
  }

  .form-submit {
    position: absolute;
    right: 20px;
    top: 8px;
    z-index: 2;
  }

  @media (max-width: (@screen-md-min - 1)) {
    .navbar & { margin: 20px 0 0 0; }
  }

  @media (min-width: @screen-md-min) {
    float: right;
    margin: 0 10px 0 5px !important; // becasue rule on override.less
    width: 5%;

    .form-text {
      display: none;
      // height: 32px;
      padding: 0px 35px 2px 10px;
    }

    .form-submit {
      right: 8px !important;
      top: 4px;
    }
  }
}

// -- Testimonial --
#block-block-4 {
  background: @gray-light;
  position: relative;

  > .block-content {
    padding: 60px 0 30px;
    overflow: auto;

    &::before,
    &::after {
      background: @gray-light;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      right: 100%;
      top: 0;
      width: 50%;
    }

    &::after {
      left: 100%;
      right: auto;
    }
  }

  img { display: block; }

  .lnk-more {
    display: inline-block;
    margin-left: 3.5em;
  }

  @media (max-width: (@screen-xs-min - 1)) {

    div[class^="col-xs-"] {
      float: none;
      width: auto;
    }

    img { margin-bottom: 20px; }
  }
}

// -- Staff directory --
#block-views-staff-directory-block {
  margin-top: 40px;
  position: relative;

  .view-filters {
    // position: absolute;
    // z-index: 9;
    // top: 130px;
    // @media (max-width: (@screen-sm-min)) {
    //   position: relative;
    //   top: auto;
    // }

    .views-exposed-form .views-exposed-widget .form-submit {
        // margin-top: 1.78em;
    }


  }
  > .block-title {
    .underline-title;
    // position: relative;
    // background: #f1f2f6;
    // margin-bottom: 0;
    // padding-bottom: 40px;
    // padding-top: 80px;
    // position: relative;

    // &::before,
    // &::after {
    //   background: #f1f2f6;
    //   content: '';
    //   display: block;
    //   height: 100%;
    //   position: absolute;
    //   right: 100%;
    //   top: 0;
    //   width: 50%;
    // }

    // &::after {
    //   left: 100%;
    //   right: auto;
    // }
  }
  .views-bootstrap-grid-plugin-style {
   > .row {
      > div {
        padding-bottom: 3rem;
      }
    }
  }
}


.mobile-donate-button {
  display: none;

  @media (max-width: (@screen-xs)) {
    display: block;
  }
  width: 100%;
  margin: 0px;
  padding: 0px;

  .give-now-btn-container {
    width: 93%;
    margin: 0 auto;
    position: relative;
    display: block;

    a {
      margin-top: 19px;
      margin-bottom: 19px;
      width:100%
    }
  }
}

.node-type-project {
  .mobile-donate-button {
    display: none;

    @media (max-width: (@screen-xs)) {
      display: none;
    }
  }
}
#block-panels-mini-homepage-cta {
  padding: 50px 15px 30px;
  margin: 0;
  background-color: #F6F6F6;
  position: relative;

  &::before {
    content: '';
    background-color: #F6F6F6;
    display: block;
    width: 200vw;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: -50vw;
    margin-right: -50vw;
    height: 100%;
    z-index: -1;
  }

  .panel-panel {
    > div {
      .make-row();

      .pane-block {
        .make-sm-column(4);
        padding-top: 1rem;
        padding-bottom: 1rem;
        .fz-responsive(20px, 1.1);
      }
    }
  }
}

#block-views-funds-block {
  padding: 50px 15px 30px;
  margin: 0 auto;

  h2.block-title {
    .underline-title;
  }

  .item-list {
    .make-row();

    ul.funds {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction:row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      li.fund-item {
        margin: 0;
        padding: 0;
        list-style: none;
        flex: 1 0 50%;
        overflow: hidden;

        @media (min-width: (@screen-sm-min)) {
          flex: 0 0 25%;
        }
        @media (min-width: (@screen-md-min)) {
          flex: 0 0 25%;
        }
        @media (min-width: (@screen-lg-min)) {
          flex: 0 0 25%;
        }

        > .node {
          padding: 1.5rem 1.5rem 4.5rem;
        }

        .views-field-title {
          a {
            font-family: 'dinot-cond-bold';
            .fz-responsive(32px, 1.1);
            .color-link(@blue-medium);
          }
        }
      }
    }
  }
} //block-views-funds-block

#block-views-stories-block-1 {
  padding: 50px 15px 30px;
  margin: 0 auto;
  background-color: #F6F6F6;
  position: relative;

  &::before {
    content: '';
    background-color: #F6F6F6;
    display: block;
    width: 200vw;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: -50vw;
    margin-right: -50vw;
    height: 100%;
    z-index: -1;
  }

  h2.block-title {
    .underline-title;
  }

  .item-list {
    .make-row();

    ul.stories {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction:row;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;

      li {
        margin: 0;
        padding: 0;
        list-style: none;
        flex: 1 0 100%;
        overflow: hidden;

        @media (min-width: (@screen-sm-min)) {
          flex: 0 0 33%;
        }
        @media (min-width: (@screen-md-min)) {
          flex: 0 0 33%;
        }
        @media (min-width: (@screen-lg-min)) {
          flex: 0 0 33%;
        }

        > .node {
          padding: 1.5rem 1.5rem 4.5rem;
        }
      }
    }
  }
}

#block-views-giving-page-blocks-block-3 {
  padding: 50px 15px 30px;

  background-color: #F6F6F6;
  position: relative;

  &::before {
    content: '';
    background-color: #F6F6F6;
    display: block;
    width: 200vw;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: -50vw;
    margin-right: -50vw;
    height: 100%;
    z-index: -1;
  }

  h2.block-title {
    .underline-title;
  }

  .views-field-field-story-related-stories .field-content {
    .make-row();

    > ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction:row;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;

      li {
        margin: 0;
        padding: 0;
        list-style: none;
        flex: 1 0 100%;
        overflow: hidden;

        @media (min-width: (@screen-sm-min)) {
          flex: 0 0 33%;
        }
        @media (min-width: (@screen-md-min)) {
          flex: 0 0 33%;
        }
        @media (min-width: (@screen-lg-min)) {
          flex: 0 0 33%;
        }

        > .node {
          padding: 1.5rem 1.5rem 4.5rem;
        }
      }
    }
  }
}


#block-views-homepage-slider-block {
  max-width: 1920px;
  margin: 0 auto;
}

#block-views-stories-block-2 {
  .block-title {
    margin-top: 0px;
  }
}
#block-views-how-to-give-block {
  .block-title {
    margin-top: 0px;
  }
}

.view-id-faculties_and_departments_terms.view-display-id-block_1 {
  padding: 30px 15px 50px;

  .item-list {
    .make-row();

    ul.faculties {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction:row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      li.faculty {
        margin: 0;
        padding: 0;
        list-style: none;
        flex: 1 0 100%;
        overflow: hidden;
        display: block;

        @media (min-width: (@screen-sm-min)) {
          flex: 0 0 50%;
        }
        @media (min-width: (@screen-md-min)) {
          flex: 0 0 33%;
        }
        @media (min-width: (@screen-lg-min)) {
          flex: 0 0 25%;
        }

        .vocabulary-support-departments-faculties.view-mode-icon {
          padding: 1.5rem 1.5rem 4.5rem;
        }
      }
    }
  }
}

.view-id-support_departments_and_faculties.view-display-id-page,
.view-id-faculties_and_departments_terms.view-display-id-block_2 {
  padding: 50px 0px;
  position: relative;

  &::before {
    content: '';
    background-color: #F6F6F6;
    display: block;
    width: 200vw;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: -50vw;
    margin-right: -50vw;
    height: 100%;
    z-index: -1;
  }

  .view-header {
    h2 {
      .fz-responsive(28px, 1.1);
      .underline-title;
    }
  }
  .item-list {
    .make-row();

    ul.funds {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction:row;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;

      li.fund-item {
        margin: 0;
        padding: 0;
        list-style: none;
        flex: 1 0 100%;
        overflow: hidden;

        @media (min-width: (@screen-sm-min)) {
          flex: 0 0 25%;
        }
        @media (min-width: (@screen-md-min)) {
          flex: 0 0 25%;
        }
        @media (min-width: (@screen-lg-min)) {
          flex: 0 0 25%;
        }

        > .views-field-field-related-funds {
          padding: 1.5rem 1.5rem 4.5rem;
        }
      }
    }
  }
}



#block-views-staff-directory-block-1 {
  padding: 65px 0px;

  h2.block-title,
  h2.pane-title {
    .fz-responsive(28px, 1.1);
    .underline-title;
  }

  .view-header {
    text-align: center;
    padding: 0 0 40px;
    .fz-responsive(22px, 1.1);
  }

  .view-content {
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    .staff-member {
      padding: 0;
      flex: 1 0 100%;
      overflow: hidden;

      @media (min-width: (@screen-sm-min)) {
        flex: 0 0 50%;
      }
      @media (min-width: (@screen-md-min)) {
        flex: 0 0 33%;
      }
      @media (min-width: (@screen-lg-min)) {
        flex: 0 0 33%;
      }

      > .node {
        padding: 1.5rem 1.5rem 3.5rem;
      }
    }
  }
}

#mobile-funds-raised,
#block-views-donation-feed-block-1 {
  display: none;
  min-height: 260px;
  border-top: 13px solid @brand-primary;
  background: #E8E8E8 url(../images/funds-raised.jpg) center center no-repeat;
  background-size: cover;
  text-align: center;
  box-shadow: 0 1px 4px rgba(0,0,0,0.26);
  padding: 20px 0;
  margin: 40px 0;

  @media (min-width: (@screen-sm-min)) {
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
  }

  .raised {
    font-family: 'dinot-cond-bold';
    .fz-responsive(24px, 1.1);
    color: #4A4A4A;
    text-align: center;
    text-transform: uppercase;
  }

  .views-field-field-use-calculated-total,
  .total-raised {
    font-family: 'dinot-cond-bold';
    .fz-responsive(53px, 1);
    color: #4A4A4A;
    text-align: center;
  }

  // .views-field-field-show-donations
  .supporters {
    font-family: 'dinot';
    .fz-responsive(18px, 1.1);
    color: #4A4A4A;
    letter-spacing: 0;
    text-align: center;
    display: block;
    padding-top: 1rem;
  }

  .views-field-field-funding-goal {
    font-family: 'dinot-cond-bold';
    .fz-responsive(24px, 1);
    color: #4A4A4A;
    text-align: center;
    display: block;
    padding-top: 1rem;
  }
}

#block-views-donation-feed-block-2 {
  margin: 0;

  .supporters {
    font-family: 'dinot-cond-bold';
    .fz-responsive(24px, 1);
    color: #4A4A4A;
    text-align: center;
    text-transform: uppercase;
    .underline-title;
  }
}

#block-views-donation-feed-block {
  margin-top: 0;
}

#block-views-donation-impact-block-1,
#donation-link {
  margin: 230px 0 20px;
  display: none;

  @media (min-width: @screen-sm-min) {
    display: block;
  }

  a.donation-link {
    .btn-primary;
    display: block;
    font-family: 'dinot-cond-bold';
    .fz-responsive(38px, 1);
    line-height: 1;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    padding: 17px;

    // white-space: nowrap;
  }
}

#block-views-donation-feed-block-1 + #block-views-donation-impact-block-1 {
  margin: 0 0 20px;
}

#block-views-donation-feed-block-1 + .share-project,
#block-views-donation-impact-block-1 + .share-project {
  margin: 0;
}

.donation-impact-intro + .panel-separator + .donation-impact-list {
  h2.pane-title {
    display: none;
  }
}

#mini-panel-project_title_unlinked,
#mini-panel-project_title {

  .share-title {
    margin: 10px auto 0 !important;
    display: block;

    @media (min-width: (@screen-sm-min)) {
      display: none;
    }

    h3 {
      margin-bottom: 5px;

      &::after {
        margin-top: 5px;
      }
    }
  }

  #share-this {
    margin: 0 auto !important;
    display: block;
    width: 100%;
    max-width: 350px;

    @media (min-width: (@screen-sm-min)) {
      display: none;
    }
  }

  #donation-link {
    width: 100%;
    max-width: 350px;
    margin: 20px auto 10px !important;
    display: block;

    @media (min-width: (@screen-sm-min)) {
      display: none;
    }
  }

  #mobile-funds-raised {
    width: 100%;
    max-width: 350px;
    margin: 10px auto;
    min-height: 0;
    padding: 25px 20px;
    display: block;
    border-top: 6px solid #ac132a;

    .views-field-field-show-donations {
      margin: 0;
    }

    .supporters {
      padding-top: 1rem;
    }

    .views-field-field-funding-goal {
      padding-top: 1rem;
    }

    @media (min-width: (@screen-sm-min)) {
      display: none;
    }
  }

} //#mini-panel-project_title

//Donation Impact List
// Intro
.donation-impact-list,
.donation-impact-intro {
  margin: 60px 0 0;
  .fz-responsive(20px, 1.5);

  @media (min-width: (@screen-sm-min)) {
    margin: 60px 0;
  }

  h2.pane-title {
    .underline-title;
    margin: 0;
    text-transform: uppercase;
    text-align: left;


    &:after {
      margin: 15px 0 40px 0;
    }
  }
}

.page-node-messages,
.page-node-updates,
.node-type-project,
.page-node-supporters {

  #block-system-main {
    .field-name-donation-menu {
      .make-xs-column(12);
    }
  }
  .share-project {
    margin: 230px 0 0;
    display: none;

    @media (min-width: @screen-sm-min) {
      display: block;
    }

    h3 {
      font-family: 'dinot-cond-bold';
      .fz-responsive(24px, 1);
      color: #4A4A4A;
      text-align: center;
      text-transform: uppercase;
      .underline-title;
    }
  }

  .sharethis-wrapper {
    text-align: center;
  }
}

#block-sharethis-sharethis-block {
  margin: 0 0 40px;
  text-align: center;
  display: none;

  @media (min-width: @screen-sm-min) {
    display: block;
  }
}



#block-views-giving-page-blocks-block,
#block-views-giving-page-blocks-block-1 {

  .views-field-field-get-in-touch-title {
    .fz-responsive(44px, 1);
    .underline-title;
    text-align: left;
    text-transform: uppercase;
    margin: 0 0 20px;

    &:after {
      margin: 15px 0 0 0;
    }
  }
  .views-field-field-get-in-touch-intro,
  .views-field-field-related-planners {
    padding: 0 0 20px 0;
  }
  .views-field-field-related-planners > .field-content {

    > ul {
      padding: 0;
      margin: 0;
      list-style: none;
      .make-row();
      display: flex;
      flex-direction:row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      li {
        padding: 0;
        margin: 0;
        list-style: none;
        flex: 1 0 100%;

        @media (min-width: (@screen-sm-min)) {
          flex: 0 0 100%;
        }
        @media (min-width: (@screen-lg-min)) {
          flex: 0 0 100%;
        }
      }
    }


    .node-staff-directory {
      padding: 1.5rem 1.5rem 3.5rem;

      .group-names,
      .field-name-title h2 {
        font-family: 'dinot-bold';
        font-size: 18px;
        margin: 0 0 10px;
      }
    }
  }
} //#block-views-giving-page-blocks-block


#block-views-giving-page-blocks-block-1 {

  .views-field-field-get-in-touch-title {
    margin: 40px 15px 20px;

    &:after {
      margin: 15px 0 0 0;
    }
  }

  .views-field-field-related-planners > .field-content {
    > ul {
      li {
        flex: 1 0 100%;

        @media (min-width: (@screen-sm-min)) {
          flex: 1 0 45%;
        }
        @media (min-width: (@screen-md-min)) {
          flex: 1 0 45%;
        }
      }
    }

    // .node-staff-directory {
    //   flex: 1 0 100%;
    //   overflow: hidden;

    //   @media (min-width: (@screen-sm-min)) {
    //     flex: 0 0 50%;
    //   }
    //   @media (min-width: (@screen-md-min)) {
    //     flex: 0 0 50%;
    //   }
    // }
  }
} //#block-views-giving-page-blocks-block

.jump-menu-intro {
  margin-bottom: 0;

  h2.block-title {
    .underline-title;
  }
}


#block-panels-mini-types-of-fundraising {
  padding: 50px 15px 30px;
  background-color: #F6F6F6;
  position: relative;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: none;

  h2.block-title {
    .underline-title;
  }

  .panels-flexible-region-inside {
    padding-top: 20px;
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    @media (min-width: (@screen-lg-min)) {
      flex-wrap: nowrap;
    }

    .panel-pane {
      flex: 0 1 50%;
      overflow: hidden;
      padding: 1.5rem;

      @media (min-width: (@screen-sm-min)) {
        flex: 1 1 20%;
      }
      @media (min-width: (@screen-lg-min)) {
        flex: 1 1 20%;
      }
    }
  }


}


.panel-separator {
  flex: 0 0 0px;
  padding: 0;
  margin: 0;
  display: none;
  width: 0;
  height: 0;
}
