//
// Admin menu
// ============================================================================
html body.admin-menu {
  margin-top: 59px !important;
  @media (min-width: @screen-sm-min) { margin-top: 29px !important; }
}


//
// Navbar
// ============================================================================

//Navbar change the hamburgur to an X
.navbar-toggle {
  border: none;
  background: transparent !important;

  &:hover {
    background: transparent !important;
  }

  .icon-bar {
    width: 26px;
    height: 3px!important;
    transition: all 0.2s;
    // -webkit-transition: all 0.2s;
    // -ms-transition: all 0.2s;
  }
  .top-bar {
    transform: rotate(45deg);
    // -webkit-transform: rotate(45deg);
    // -ms-transform: rotate(45deg);
    transform-origin: 10% 10%;
    // -webkit-transform-origin: 10% 10%;
  }
  .middle-bar {
    opacity: 0;
  }
  .bottom-bar {
    transform: rotate(-45deg);
    // -webkit-transform: rotate(-45deg);
    // -ms-transform: rotate(-45deg);
    transform-origin: 10% 90%;
    // -webkit-transform-origin: 10% 90%;
  }
}

.navbar-toggle.collapsed {
  .top-bar {
    transform: rotate(0);
    // -webkit-transform: rotate(0);
    // -ms-transform: rotate(0);
  }
  .middle-bar {
    opacity: 1;
  }
  .bottom-bar {
    transform: rotate(0);
    // -webkit-transform: rotate(0);
    // -ms-transform: rotate(0);
  }
}

//
// Region top nav
// ============================================================================
.region-top-nav {

  .menu {
    font-size: 17px;
    float: right;

    li {
      float: left;
      padding: 0;

      a {
        // border-right: 1px solid #D4D5D6;
        color: @blue-medium;
        padding: 3px 15px;
      }
    }

    .caret,
    .dropdown-menu {
      display: none;
    }
  }
}

//
// Region Navigation
// ============================================================================
.region-navigation {

  .menu {
    background-color: #dddddd;
    border: 1px solid #D4D5D6;
    font-family: 'dinot-cond-bold';

    @media (min-width: @screen-md-min) {
      display: none;
      background-color: #fff;
      margin-top: 0px;
      border: 0 none;
    }

    li {
      display: inline;
      float: left;
      margin: 0;
      padding: 10px 0;
      width: 100%;
      border-bottom: 1px solid #D4D5D6;
      &.first {
        @media (min-width: @screen-md-min) {
          a {
            // border-left: 1px solid #D4D5D6;
          }
        }
      }

      &.last.hidden-md  {
        border-bottom: 0 none;
        a {
          border-right: 0 none;
        }
      }

      @media (min-width: @screen-md-min) {
        padding: 0;
        width: auto;
        border-bottom: 0 none;
      }
    }

    a {
      color: @blue-medium;
      font-size: 24px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      padding: 5px 25px;

      &:hover,
      &:focus,
      &.active-trail {
        background-color: transparent;
        color: @brand-primary;
      }

      @media (min-width: @screen-sm-min) {
        font-size: 20px;
      }

      @media (min-width: @screen-md-min) {
        font-size: 22px;
      }

      @media (min-width: @screen-lg-min) {
        font-size: 24px;
      }
    }

    .caret,
    .dropdown-menu {
      display: none;
    }
  }

  ul.nice-menu {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: 'dinot-cond-bold';

    @media (min-width: @screen-md-min) {
      display: flex;
      flex-direction:row;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: nowrap;
      width: 100%;
    }

    li {
      flex: 1 1 auto;
      list-style: none;
      padding: 0;
      margin: 0;
      background: none;
      border: 0;


      a {
        display: block;
        color: @blue-medium;
        font-size: 16px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        padding: 5px 15px;
        white-space: nowrap;
        text-align: center;

        @media (min-width: @screen-sm-min) {
          font-size: 16px;
        }

        @media (min-width: @screen-md-min) {
          font-size: 20px;
        }

        @media (min-width: @screen-lg-min) {
          font-size: 24px;
        }

        &.hide-on-desktop {
          @media (min-width: @screen-md-min) {
            display: none;
          }
        }

        &:hover,
        &:focus,
        &.active-trail {
          background: #F0F0F0;
          color: @brand-primary;

          .caret {
            background-image: url("../images/caret-red.svg");
            background-size: contain;
          }
        }
      }

      &.over {
        a {
          color: @red;
          background: #F0F0F0;

          .caret {
            background-image: url("../images/caret-red.svg");
            background-size: contain;
          }
        }
      }

      .caret {
        display: inline-block;
        width: 17px;
        height: 12px;
        margin-left: 10px;
        vertical-align: middle;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        background: url("../images/caret-red.svg") top left no-repeat;
        background-size: contain;
      }

      ul.dropdown-menu {
        background: #F0F0F0;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
        z-index: -1;
        visibility: visible;
        border: 0;
        width: 100%;
        top: 0;
        border-radius: 0;
        padding: 60px 0 0 0;
        margin: 0;

        ul {
          top: 0;
          position: relative;
          width: 100%;
          border: 0;
          display: block !important;
          visibility: visible;
          margin: 0;
          padding: 0;


          li {
            width: 100%;
            display: block;
            border-top: 1px solid #E4E7E8;

            a {
              color: @blue-medium;
              border-top: 1px solid #fff;
              display: block;
              text-transform: none;
              font-size: 14px;
              text-align: center;
              padding: 1rem 10px;
              line-height: 1.1;
              white-space: normal;

              @media (min-width: @screen-sm-min) {
                font-size: 16px;
              }

              @media (min-width: @screen-md-min) {
                font-size: 18px;
              }

              @media (min-width: @screen-lg-min) {
                font-size: 20px;
              }

              &:hover,
              &:focus,
              &.active-trail {
                background: transparent;
                color: @brand-primary;
              }


            }
          }


        }
      }
    }
  }
}

.region-mobile-menu {
  .menu-block-wrapper {
    ul.menu {
      font-family: 'dinot-cond-bold';

      li {
        margin: 0;
        padding: 0;
        width: 100%;
        border-bottom: 1px solid #dedede;

        a {
          display: block;
          color: @red;
          font-size: 18px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          padding: 10px 15px;
          white-space: nowrap;
          text-align: left;

          &.hide-on-desktop {
            @media (min-width: @screen-md-min) {
              display: none;
            }
          }

          &:hover,
          &:focus,
          &.active-trail {
            background: #F0F0F0;
            color: @brand-primary;

            .caret {
              background-image: url("../images/caret-red.svg");
              background-size: contain;
            }
          }
        }
        ul.dropdown-menu {
          margin: 0;
          padding: 0;

          li {
            margin: 0;
            padding: 0;
            width: 100%;
            border-top: 1px solid #dedede;
            border-bottom: 0;

            a {
              color: @blue-medium;
              font-size: 14px;
              letter-spacing: 0.05em;
              text-transform: uppercase;
              padding: 5px 25px;

              &:hover,
              &:focus,
              &.active-trail {
                background-color: transparent;
                color: @brand-primary;
              }
            }
          }
        }
      }
    }
  }
} //region-mobile-menu



//
// Breadcrumbs
// ============================================================================
.easy-breadcrumb {
  color: @gray-dark;
  font-size: 16px;
  margin: 0 auto;
  max-width: @container-max-width;
  padding: 17px floor((@grid-gutter-width / 2));

  a { .color-link(@blue-medium); }

  @media (max-width: (@screen-md-min - 1)) {
    display: none;
  }
}

//
// Menu blocks on Second Sidebar
// ============================================================================
.region-sidebar-second > .block-menu-block {
  background: @gray-light;
  border-top: 0 none;
  padding: 15px 30px 60px 30px;

  .block-title { margin-bottom: 30px; }

  .nav {

    li {
      line-height: 1.1em;
      margin-bottom: 14px;
    }

    a {
      color: @blue-medium;
      display: inline;
      padding: 0;

      &:hover,
      &:focus,
      &.active {
        background: transparent;
        color: @gray-dark;
      }
    }
  }
}
ul.giving-vehicles {
  strong {
    font-family:'dinot-cond-bold' !important;
  }
}


.pane-menu-menu-donation-submenu {
  padding-bottom: 50px;

  ul.menu {
    li {
      display: inline-block;
      padding: 0 30px 0 0;
      margin: 0;

      @media (min-width: (@screen-sm)) {
        padding-right: 50px;
      }

      @media (min-width: (@screen-md)) {
        padding-right: 70px;
      }

      @media (min-width: (@screen-lg)) {
        padding-right: 90px;
      }

      a {
        color: @brand-primary;
        font-family: 'dinot-cond-bold';
        .fz-responsive(24px, 1.2);
        display: block;
        text-transform: uppercase;
        background: none;
        padding: 5px 0;
        border-bottom: 5px solid transparent;

        @media (min-width: (@screen-sm)) {
          border-bottom: 7px solid transparent;
        }
        @media (min-width: (@screen-md)) {
          border-bottom: 10px solid transparent;
        }


        &:hover,
        &:focus,
        &:active {
          background: none;
          border-color:#E4E7E8;
        }

        &.active {
          color: #4A4A4A;
          border-color:#E4E7E8;
        }
      }
    }
  }
}



// -- Footer menu --
#block-menu-menu-footer-menu {
  font-family: 'dinot-cond-bold';
  letter-spacing: 0.05em;
  text-transform: uppercase;

  .nav {
    list-style: none;

    li {
      display: inline-block;
      margin: 0;

      a {
        display: block;
        padding: 5px 10px 5px 0;

        @media (min-width: (@screen-md-min)) {
          padding: 5px 10px;
        }


        &:hover, &:focus { background: transparent; }
      }

      &.first {
        a {

          @media (min-width: (@screen-md-min)) {
            padding-left: 0;
          }
        }
      }
    }
  }
}

// -- Social menu --
#block-menu-menu-social-menu {
  @icon-size: 36px;
  padding: 0;
  list-style-type: none;
  margin-bottom: 15px;
  display: flex;
  flex-direction:row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  .block-title {
    text-transform: uppercase;
    margin-bottom: 0;
    margin-right: 1rem;
    .fz-responsive(24px);
  }

  ul.menu {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      padding: 0;
      margin: 5px 5px 5px 0;
      display: inline-block;
      vertical-align: top;
    }

    a:hover, a:focus { background-color: transparent; }

    .icon {
      background: transparent left top no-repeat;
      background-size: contain;
      display: block;
      height: @icon-size;
      overflow: hidden;
      text-indent: 9999px;
      width: @icon-size;

      &.twitter {
        background-image: url('../images/social/twitter.svg');

        &:hover, &:focus {
          background-image: url('../images/social/twitter_hover.svg');
        }
      }

      &.facebook {
        background-image: url('../images/social/facebook.svg');

        &:hover, &:focus {
          background-image: url('../images/social/facebook_hover.svg');
        }
      }

      &.instagram {
        background-image: url('../images/social/instagram.svg');

        &:hover, &:focus {
          background-image: url('../images/social/instagram_hover.svg');
        }
      }


      &.linkedin {
        background-image: url('../images/social/linkedIn.svg');

        &:hover, &:focus {
          background-image: url('../images/social/linkedIn_hover.svg');
        }
      }

      &.email {
        background-image: url('../images/social/email.svg');

        &:hover, &:focus {
          background-image: url('../images/social/email_hover.svg');
        }
      }
    }

    .sfu {
      background: url('../images/SFU_logo_footer.png') no-repeat;
      background-size: cover;
      display: block;
      height: 38px;
      overflow: hidden;
      text-indent: 9999px;
      width: 76px;
    }
  }
}


#block-better-jump-menu-menu-fundraising {
  padding-bottom: 40px;

  h2.block-title {
    text-align: center;
    .fz-responsive(24px);
  }
  form#better-jump-menu {
    .container-inline {
      display: flex;
      flex-direction:row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      max-width: 600px;
      margin: 0 auto;

      .form-item {
        flex: 1 1 auto;
        display: block;
        width: 100%;
        margin-bottom: 0;


        select {
          padding: 20px 30px;
          height: 68px;
        }
      }
      button.btn {
        font-size: 40px;
        padding: 4px 30px;
      }
      // .form-actions {
      //   flex: 0 0 112px;
      //   display: block;
      //   width: 100%;



      // }
    }
  }
}


//share this

.sharethis-wrapper {
  text-align: center;

  .st_twitter_custom,
  .st_facebook_custom,
  .st_instagram_custom,
  .st_linkedin_custom,
  .st_email_custom {
    background: transparent left top no-repeat;
    background-size: contain;
    display: inline-block;
    overflow: hidden;
    text-indent: 9999px;
    cursor: pointer;
    margin: .4rem;
    width: 45px;
    height: 45px;

    @media (min-width: @screen-sm) {
      width: 40px;
      height: 40px;
      margin: .4rem;
    }

    @media (min-width: (@screen-md)) {
      width: 45px;
      height: 45px;
    }
  }

  .st_twitter_custom {
    background-image: url('../images/social/twitter.svg');

    &:hover, &:focus {
      background-image: url('../images/social/twitter_hover.svg');
    }
  }

  .st_facebook_custom {
    background-image: url('../images/social/facebook.svg');

    &:hover, &:focus {
      background-image: url('../images/social/facebook_hover.svg');
    }
  }

  .st_instagram_custom {
    background-image: url('../images/social/instagram.svg');

    &:hover, &:focus {
      background-image: url('../images/social/instagram_hover.svg');
    }
  }


  .st_linkedin_custom {
    background-image: url('../images/social/linkedIn.svg');

    &:hover, &:focus {
      background-image: url('../images/social/linkedIn_hover.svg');
    }
  }

  .st_email_custom {
    background-image: url('../images/social/email.svg');

    &:hover, &:focus {
      background-image: url('../images/social/email_hover.svg');
    }
  }
}

#stuck {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;

  &.sticky {
    display: block;

    @media (min-width: (@screen-sm)) {
      display: none;
    }
  }
}

#sticky-trigger .pane-content {
  display: none;
}
#block-views-donation-impact-block-4 {
  width: 100%;

  a.donation-link {
    .btn-primary;
    display: block;
    font-family: 'dinot-cond-bold';
    .fz-responsive(38px, 1);
    line-height: 1;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    padding: 17px;

    // white-space: nowrap;
  }
}
