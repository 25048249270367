.views-exposed-form label { font-size: 18px; }

//
// Draggable Views
// ============================================================================
.view.tablesort {
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: @screen-sm-min) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (min-width: @screen-md-min) {
    padding-left: 4.2484%;
    padding-right: 4.2484%;
  }
}

//
// Main Slider
// ============================================================================
.view-homepage-slider {

  // -- Block display
  &.view-display-id-block {
    position: relative;
    max-width: 1450px;
    margin: 0 auto;

    .slide__content {
      display: flex;
      flex-direction:row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      // background: @gray-lighter radial-gradient(circle, @gray-lighter 0%, @gray-light 100%);

      @media (min-width: @screen-sm-min) {
        flex-wrap: nowrap;
      }

    }
    .views-field-field-slide-homepage-mobile {
      @media (min-width: @screen-sm-min) {
        display: none;
      }
    }

    .views-field-field-slide-homepage-desktop {
      display: none;

      @media (min-width: @screen-sm-min) {
        display: block;
        height: 100%;
        flex: 1 0 65%;
      }

      @media (min-width: @screen-lg-min) {
        flex: 1 0 65%;
      }
    }

    .slide-text.views-fieldset {
      padding: 20px;
      flex: 1 0 100%;

      @media (min-width: @screen-sm-min) {
        height: 100%;
        flex: 1 0 35%;
      }

      @media (min-width: @screen-lg-min) {
        height: 100%;
        flex: 1 0 35%;
      }

      .views-field-field-homepage-headline {
        font-family: 'dinot-cond-bold';
        font-size: 45px;
        line-height: .9;
        padding-bottom: 10px;
        text-transform: uppercase;

        @media (min-width: @screen-sm-min) {
          font-size: 36px;
        }
        @media (min-width: @screen-md-min) {
          font-size: 45px;
        }
        @media (min-width: @screen-lg-min) {
          font-size: 55px;
        }


        a {
          .color-link(@blue-medium);
        }


      }

      .views-field-body {
        font-family: 'dinot';
        padding-bottom: 20px;
        font-size: 16px;
        line-height: 1.3;

        @media (min-width: @screen-sm-min) {
          font-size: 14px;
          line-height: 1.3;
        }
        @media (min-width: @screen-md-min) {
          font-size: 18px;
          line-height: 1.3;
        }
        @media (min-width: @screen-lg-min) {
          font-size: 20px;
          line-height: 1.3;
        }

        p {
          font-size: 16px;
          line-height: 1.3;

          @media (min-width: @screen-sm-min) {
            font-size: 14px;
            line-height: 1.3;
          }
          @media (min-width: @screen-md-min) {
            font-size: 18px;
            line-height: 1.3;
          }
          @media (min-width: @screen-lg-min) {
            font-size: 20px;
            line-height: 1.3;
          }
        }


        // @media (min-width: @screen-md-min) { font-size: 1.1em; }
        // @media (min-width: @screen-lg-min) { font-size: 1.4em; }
      }

      .views-field-view-node a {
        background: #FFF;
        color: @brand-primary;
        display: inline-block;
        font-family: 'dinot-cond-bold';
        font-size: 1.2em;
        padding: 5px 10px;
        text-transform: uppercase;


        @media (min-width: @screen-md-min) {
          font-size: 1.1em;
        }
        @media (min-width: @screen-lg-min) {
          font-size: 1.4em;
        }
      }

    }

    .slide-text-img-wrap.views-fieldset {
      width: 25%;
      float: left;
      // background-color: #626264;
      padding: 20px;
      padding-left: 0;

      @media (min-width: @screen-md-min) {
        padding-left: 20px;
        padding-top: 10px;
      }

      @media (min-width: @screen-lg-min) {
        padding-top: 20px;
      }

      .views-field-field-slide-body-image {

        img {
          width: auto;
          height: auto;
          max-width: 100%;
        }
      }
    }

    .views-field-field-headline-link-2 a {
      font-size: 14px;
      line-height: 1;

      @media (min-width: @screen-sm-min) {
        font-size: 14px;
      }
      @media (min-width: @screen-md-min) {
        font-size: 16px;
      }
      @media (min-width: @screen-lg-min) {
        font-size: 18px;
      }
    }

    .flex-direction-nav {

      a {
        content: "";
        height: 75px;
        @media (min-width: @screen-md-min) {
          height: 55px;
          width: 55px;
        }

        &.flex-prev {
          background: url('../images/hp-slider-arrow-left-mobile.png') no-repeat scroll left center transparent;
          text-indent: -99999px;
          @media (min-width: @screen-md-min) {
            background: url('../images/hp-slider-arrow-left-desktop.png') no-repeat scroll center center #adadad;
          }
        }

        &.flex-next {
          background: url('../images/hp-slider-arrow-right-mobile.png') no-repeat scroll right center transparent;
          text-indent: 99999px;
          @media (min-width: @screen-md-min) {
            background: url('../images/hp-slider-arrow-right-desktop.png') no-repeat scroll center center #adadad;
          }
        }

      }
    }

    .flexslider:hover .flex-direction-nav .flex-next,
    .flexslider:hover .flex-direction-nav .flex-prev {
      opacity: 1;
    }
  }
}

.slick-dots {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  left: 45%;
  bottom: 5px;
  z-index: 9;

  @media (min-width: @screen-sm-min) {
    position: absolute;
    left: auto;
    right: 20px;
  }

  li{
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 5px 3px;
    height: 10px;
    width: 10px;
    border-radius: 50px;
    background: #9B9B9B;
    cursor: pointer;

    @media (min-width: @screen-md) {
      margin: 5px 4px;
      height: 12px;
      width: 12px;
    }

    button {
      -webkit-appearance: none;
      appearance: none;
      display: none;
      border: 0;
      text-indent: -9999px;
      border-radius: 50px;
      background: #9B9B9B;
      height: 15px;
      width: 15px;
    }

    &.slick-active {
      background: #4A4A4A;

      button {
        background: #4A4A4A;
      }
    }
  }
}

//
// Support Departments and Faculties
// ============================================================================
.view-support-departments-and-faculties {

   // -- Page: related funds
  &.view-display-id-page {

    .view-header {

      h2 {
        .fz-responsive(28px, 1.1);
        .underline-title;
      }
    }
  }

  // -- Page: Promoted to front page
  &.view-display-id-page_2 {

    >.view-header .block-title {
      font-size: 30px;
      margin-bottom: 55px;

      // letter-spacing: 0.05em;
      text-align: center;
      text-transform: uppercase;
    }

    img { display: block; }

    .views-field { margin-bottom: 25px; }

    .views-field-title,
    .views-field-field-feature-title {

      h2 {
        margin-top: 0;
        .fz-responsive(@font-size-h2);
        text-transform: uppercase;
        a {
          color: @blue-medium !important;
          &:hover {
            color: #00374a !important;
          }
        }
      }
    }

    .views-field-body {
      // font-size: @font-size-large;
      // line-height: 1.3em;
    }

    .views-field-view-node a {
      .btn();
      .btn-primary();
      font-size: 23px;
      &:hover {
        background: @blue-medium;
        border-color: @blue-medium;
        color:white;
      }
    }
  }

  // -- Page: Giving Opportunities
  &.view-display-id-page_3 {
    padding-top: 0px;
    position: relative;

    > .attachment-before {

      div[class*="col-"] {
        margin-bottom: 70px;
        @media (max-width: (@screen-md-min - 1)) {
          margin-bottom: 45px;
        }
       }

    }

    > .view-header {
      background: #F6F6F6;
      overflow-y: hidden;
      padding: 40px 0 0;
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;

      h2 {
        font-size: 30px;
        margin-bottom: 55px;
        margin-top: 0px;
        // letter-spacing: 0.05em;
        text-align: center;
        text-transform: uppercase;
        font-family: 'dinot-cond-bold';
        line-height: 0.9em;

        .underline-title;
      }
    }

    > .view-filters { margin-bottom: 40px; }

    > .view-content {

      .item-list > ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction:row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        li {
          margin: 0;
          padding: 0;
          list-style: none;
          flex: 1 0 100%;
          overflow: hidden;

          @media (min-width: (@screen-sm-min)) {
            flex: 0 0 25%;
          }
          @media (min-width: (@screen-md-min)) {
            flex: 0 0 25%;
          }
          @media (min-width: (@screen-lg-min)) {
            flex: 0 0 25%;
          }

          > .node {
            padding: 1.5rem 1.5rem 4.5rem;
          }
        }
      }
    }

    #views-exposed-form-support-departments-and-faculties-page-3 {
      background: #F6F6F6;
      overflow-y: hidden;
      padding: 20px 0 0 0;
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;

      .views-exposed-widgets {
        padding: 0;
        .container-fluid;
        display: flex;
        flex-direction:row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;


        #edit-field-department-or-faculty-tid-wrapper {
          flex: 0 0 100%;
          order: 0;

          @media (min-width: @screen-sm-min) {
            flex: 0 0 50%;
          order: 0;
          }

          @media (min-width: @screen-md-min) {
            flex: 0 0 30%;
            order: 0;
          }
        }

        #edit-field-project-type-tid-wrapper {
          flex: 1 1 100%;
          padding: 0;
          order: 4;

          @media (min-width: @screen-md-min) {
            flex: 1 0 60%;
            order: 3;
          }
        }

        #edit-keys-wrapper {
          flex: 1 0 75%;
          order: 1;

          @media (min-width: @screen-sm-min) {
            flex: 1 0 30%;
            order: 1;
          }

          @media (min-width: @screen-md-min) {
            margin-top: -90px;
            flex: 0 0 20%;
            order: 3;
          }
        }
        .views-submit-button {
          flex: 0 0 10%;
          order: 2;

          @media (min-width: @screen-sm-min) {
            flex: 0 0 10%;
            order: 2;
          }

          @media (min-width: @screen-md-min) {
            margin-top: -90px;
            flex: 0 0 10%;
            order: 3;
          }
        }
      }

      .views-reset-button {
        flex: 0 0 100%;
        order: 5;
        text-align: right;
        padding: 20px 0;


          @media (min-width: @screen-md-min) {
            flex: 0 0 100%;
            order: 5;
          }

          button {
            font-size: 16px;
            padding: 0;
            margin: 0;
            background: none;
            border: 0;
            color: @red;
          }
      }

      label {
        font-family: 'dinot-cond-bold';
        font-size: 18px;
        text-transform: uppercase;
      }
      .form-item-field-project-type-tid {
        .form-item {
          display: flex;
          flex-direction:row;
          justify-content: center;
          align-items: flex-start;
          flex-wrap: wrap;
          width: 100%;

          @media (min-width: @screen-md-min) {
            justify-content: space-between;
            flex-wrap: nowrap;
          }

          .form-item {
            flex: 0 1 48%;
            margin: 3px;

            @media (min-width: @screen-sm-min) {
              flex: 0 1 32%;
              margin: 3px;
            }

            @media (min-width: @screen-md-min) {
              flex: 0 1 15%;
              margin: 3px;
            }

            &.form-item-edit-field-project-type-tid-all {
              display: none;
              flex: 0 0 0px;
              padding: 0;
            }

            &.form-item-edit-field-project-type-tid- {
              &all {
                display: none;
                flex: 0 0 0px;
                padding: 0;
              }
              // Project
              &18 a {
                background-image: url("../images/funds/icon_projects-gray.svg");
                &.active {
                  background-image: url("../images/funds/icon_projects-active.svg");
                }
              }
              // Milestone
              &19 a {
                background-image: url("../images/funds/icon_milestones-gray.svg");
                &.active {
                  background-image: url("../images/funds/icon_milestones-active.svg");
                }
              }
              // Tribute
              &20 a {
                background-image: url("../images/funds/icon_tributes-gray.svg");
                &.active {
                  background-image: url("../images/funds/icon_tributes-active.svg");
                }
              }
              // Memorial
              &21 a {
                background-image: url("../images/funds/icon_memorials-gray.svg");
                &.active {
                  background-image: url("../images/funds/icon_memorials-active.svg");
                }
              }
              // Award
              &22 a {
                background-image: url("../images/funds/icon_awards-gray.svg");
                &.active {
                  background-image: url("../images/funds/icon_awards-active.svg");
                }
              }
              // Event
              &23 a {
                background-image: url("../images/funds/icon_events-gray.svg");

                &.active {
                  background-image: url("../images/funds/icon_events-active.svg");
                }
              }
            }

            a {
              display: block;
              font-family: 'dinot-cond-bold';
              font-size: 18px;
              text-transform: uppercase;
              padding: 90px 15px 15px 15px;
              background-color: #fff;
              background-position: bottom 55px center;
              background-repeat: no-repeat;
              border-radius: 5px;
              box-shadow: 0 1px 4px rgba(0,0,0,0.26);
              text-align: center;
              width: 100%;

              &:hover,
              &:focus,
              &:active {
                transition: all .4s ease-in-out;
                transform: translate3D(0,-3px,0) scale(1.01);
                box-shadow: 0 3px 4px rgba(0,0,0,0.26);
              }

              &.active {
                color: #fff;
                background-color: @red;
              }
            }
          }
        }
      }
    } //.views-widget-filter-field_project_type_tid

    .view-empty {
      padding: 40px 0;

      .view-content {
        .item-list > ul {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction:row;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;

          li {
            margin: 0;
            padding: 0;
            list-style: none;
            flex: 1 0 100%;
            overflow: hidden;

            @media (min-width: (@screen-sm-min)) {
              flex: 0 0 25%;
            }
            @media (min-width: (@screen-md-min)) {
              flex: 0 0 25%;
            }
            @media (min-width: (@screen-lg-min)) {
              flex: 0 0 25%;
            }

            > .node {
              padding: 1.5rem 1.5rem 4.5rem;
            }
          }
        }
      }
    }
  }

  &.view-display-id-block_1 {
    > .view-header {
      padding: 0 0 40px;
      font-family: 'dinot-bold';
      .fz-responsive(24px, 1.1);
    }
  }
} //.view-support-departments-and-faculties

//
// Stories
// ============================================================================
.view-stories {
  // padding-bottom:40px;
  // Block: Recent Stories
  &.view-display-id-block_1,
  &.view-display-id-block_2 {

    > .view-header {

      h2 {
        font-size: 30px;
        margin-bottom: 55px;
        text-align: center;
        text-transform: uppercase;
      }
    }

    img { display: block; }

    .views-field { margin-bottom: 25px; }

    .views-field-title {

      h2 { .fz-responsive(@font-size-h3); }

      a { .color-link(@blue-medium); }
    }

    .views-field-body {
      font-size: 18px;
      // line-height: 1.2em;
    }

    .views-field-view-node a {
      font-family: 'dinot-cond-bold';
      font-size: @font-size-small;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }

  // Page: Stories lis
  &.view-display-id-page {

    > .attachment-before {

      div[class*="col-"] {
        margin-bottom: 70px;

        @media (max-width: (@screen-md-min - 1)) {
          margin-bottom: 45px;
        }
      }
    }

    > .view-content {
      background: linear-gradient( 0deg, transparent, #f1f2f6 );
      padding-top: 80px;
      position: relative;

      &::before,
      &::after {
        background: linear-gradient( 0deg, transparent, #f1f2f6 );
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        right: 100%;
        top: 0;
        width: 50%;
      }

      &::after {
        left: 100%;
        right: auto;
      }


      .row {
        // margin-bottom: 40px;

        @media (min-width: @screen-xs-min) {
          // border-bottom: 1px solid #D1D1D2;
          // padding-bottom: 50px;
        }
      }
    }
  }
}

//
// Giving Vehicles
// ============================================================================
.view-how-to-give {

  // Block: Planning your gift
  &.view-display-id-block {

    div[class*="col-"] {
      margin-bottom: 30px;
      text-align: center;
    }

    .views-field-title {

      .icon {
        height: 96px;
        margin: 0 auto 10px auto;
        width: 96px;
      }

      .active { display: none; }

      a {

        &:hover, &:focus {

          .active { display: inline; }
          .normal { display: none; }
        }
      }

      h2 {
        font-size: 26px;
        margin-top: 0;
      }
    }
  }

  // Block: Ways to give
  &.view-display-id-block_2 {

    .views-row {
      background: #EEF0F0;
      border: 1px solid #E8E8E8;
      margin-bottom: 20px;
      padding: 40px 20px;

    }

    .views-field-field-how-to-give-icon {
      margin: 0 auto 20px auto;

      > .field-content {
        margin: 0 auto;
        max-width: 92px;
      }
    }

    .views-field-field-vehicle-short-description {
      font-size: 20px;
      line-height: 1.2em;

      h2, h3 {
        margin: 0 0 1em 0;
        text-align: center;

        @media (min-width: @screen-sm-min) { text-align: left; }
      }
    }
  }
}

//
// Staff directory
// ============================================================================

.view-staff-directory {
  // -- Block: Staff directory
  &.view-display-id-block {

    > .attachment-before {

      div[class*="col-"] {
        margin-bottom: 70px;
      }
    }

    > .view-content {
      // background: linear-gradient( 0deg, transparent, #f1f2f6 );
      padding-top: 40px;
      // position: relative;

      // &::before,
      // &::after {
      //   background: linear-gradient( 0deg, transparent, #f1f2f6 );
      //   content: '';
      //   display: block;
      //   height: 100%;
      //   position: absolute;
      //   right: 100%;
      //   top: 0;
      //   width: 50%;
      // }

      // &::after {
      //   left: 100%;
      //   right: auto;
      // }

      .views-bootstrap-grid-plugin-style > .row {
        @media (max-width: (@screen-xs-min - 1)) {
          margin-bottom: 0;
        }

        @media (min-width: @screen-xs-min) {
          // border-bottom: 1px solid #D1D1D2;
          padding-bottom: 0px;
        }
      }

    }
  }

  // -- Block: Staff directory on faculty's sidebar
  &.view-display-id-block_1 {

    .group-names,
    .field-name-title h2 {
      font-family: 'dinot-bold';
      font-size: 18px;
      margin: 0;
    }

    .field-name-field-staff-image {
      min-width: 100px;
      padding-right: 0;
    }

    @media (max-width: (@screen-sm-min - 1)) {

      .field-name-field-staff-image {
        float: left;
        width: 100px;

        + .group-content { margin-left: 110px; }
      }
    }
  }
}

.view-support-departments-and-faculties {
  .views-bootstrap-grid-plugin-style > .row {

    @media (max-width: (@screen-sm - 1)) {
      margin-bottom: 0;

      > div { margin-bottom: 47px; }
    }
  }
}


.donation-thankyou-pane {
  margin: 30px 0 60px;
  padding: 30px;
  border: 2px solid @brand-primary;
  font-family: 'dinot';
  font-weight: bold;
  font-style: italic;
  text-align: center;
}

//Donation Impact List

.view-id-donation_impact.view-display-id-block {
  margin-top: 40px;

  .donation-impact-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 2rem 0;
    border-radius: 10px;
    overflow: hidden;
    background: #F0F0F0;
    box-shadow: 0 2px 4px 0 rgba(155,155,155, 0.6);
    transition: all .5s ease;

    @media (min-width: (@screen-sm-min)) {
      justify-content: flex-start;
      align-items: stretch;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    &:hover,
    &:focus,
    &:active {
      transition: all .4s ease;
      transform: translate3D(0,-1px,0) scale(1.01);
    }

    .impact-value {
      flex: 0 0 100%;
      text-align: left;
      padding: 10px 15px;
      background: @brand-primary;
      color: #fff;

      @media (min-width: (@screen-sm-min)) {
        flex: 0 0 150px;
        padding: 30px 5px;
        text-align: center;
      }

      .impact-amount {
        font-family: 'dinot-cond-bold';
        .fz-responsive(53px, 1);
        color: #FFFFFF;
        text-align: center;
        display: inline-block;

        @media (min-width: (@screen-sm-min)) {
          display: block;
        }
      }
      .give-now {
        font-family: 'dinot-cond-bold';
        .fz-responsive(20px, 1.3);
        color: rgba(255, 255, 255, 0.7);
        text-transform: uppercase;
        padding-top: 5px;
        display: inline-block;

        @media (min-width: (@screen-sm-min)) {
          display: block;
        }
      }
    }
    .impact-desc {
      flex: 0 0 100%;
      padding: 15px;
      font-family: 'dinot';
      .fz-responsive(20px, 1.3);
      color: #4A4A4A;
      letter-spacing: 0;

      @media (min-width: (@screen-sm-min)) {
        flex: 1 1 auto;
        padding: 30px;
      }

      .title {
        color: @brand-primary;
        font-family: 'dinot-cond-bold';
        .fz-responsive(32px, .9);
        margin-top: 0;
      }
    }
  }
}


//Donation Feed
.view-id-donation_feed.view-display-id-block {

  .view-donations .views-row {
    padding: 1.5rem 0;
    text-align: center;
    font-family: 'dinot';
    font-size: 20px;
    color: #4A4A4A;
    letter-spacing: 0;
  }
  .donated-by {
  }
  .views-field-field-amount {
    .donated {
    }
  }
}

.pane-donation-feed-panel-pane-1 {
  .pane-title {
    text-align: center;
    .underline-title;
  }
}
.view-id-donation_feed.view-display-id-panel_pane_1 {
  padding-bottom: 40px;
  border-bottom: 7px solid #E8E8E8;

  .view-id-donations {
    .view-content {
      columns: 200px 4;
    }
    .views-row {
      padding: 1.5rem 0;
      text-align: center;
      font-family: 'dinot';
      font-size: 20px;
      color: #4A4A4A;
      letter-spacing: 0;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }
}

.featured-banner {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 40vh;
  min-height: 250px;
  max-height: 350px;

  @media (min-width: @screen-sm-min) {
    max-height: 350px;
  }

  @media (min-width: @screen-md-min) {
    max-height: 400px;
  }

  @media (min-width: @screen-lg-min) {
    min-height: 300px;
    max-height: 450px;;
  }
  @media (min-width: 1550px) {
    max-height: 550px;
  }
}

.view-id-banner {
  &.view-display-id-block_1,
  &.view-display-id-block_2 {

    .views-field-field-portrait-image {
      .container-fluid;
      position: relative;

      img {
        position: absolute;
        bottom: -5px;
        z-index: 9;
        width: 100px;

        @media (min-width: @screen-sm-min) {
          width: 135px;
          bottom: -10px;
        }

        @media (min-width: @screen-md-min) {
          width: 170px;
          bottom: -15px;
        }

        @media (min-width: @screen-lg-min) {
          width: 203px;
          bottom: -20px;
        }
      }
    }
  }

  &.view-display-id-block_2 {
    .views-row {
      display: flex;
      flex-direction:row;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;


      .views-field-field-portrait-image {
        .container-fluid;
        position: relative;
        flex: 0 0 100%;

        img {
          position: absolute;
          left: 15px;
          bottom: -20px;
          z-index: 9;
        }
      }

      .views-field-field-featured-image,
      .views-field-field-secondary-featured-image {
        flex: 0 0 100%;
        // height: 307px;

        @media (min-width: @screen-md-min) {
          flex: 0 0 50%;
          // height: 407px;
        }

        .secondary-banner-50-50,
        .featured-banner-50-50 {
          display: flex;
          flex-direction:row;
          width: 100%;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          height: 200px;

          @media (min-width: @screen-sm-min) {
            height: 357px;
          }

          @media (min-width: @screen-md-min) {
            height: 407px;
          }
          @media (min-width: 1550px) {
            height: 527px;
          }
        }
      }

      .views-field-field-secondary-featured-image {
        display: none;

        @media (min-width: @screen-md-min) {
          display: block;
        }
      }
    }
  }
}
