/**
 *
 * Style for all layout that doesn't belong to the header of footer.
 *
 */

//
// Bootstrap overrides
// ============================================================================
.container,
.container-fluid {
  max-width: @container-max-width;
}

//
// Highlighted Region
// ============================================================================
.highlighted.jumbotron {
  background: @gray-light;
  margin-bottom: 0;
  padding: 0;
}
.region-content-suffix,
.region-content {

  body.front & { margin-top: 70px; }
}


.region-content {
  padding-bottom: 60px;
}

.front,
.node-type-stories,
.node-type-support-departments-faculties {
  .region-content {
    padding-bottom: 0px;
  }
}

.region-content-suffix {

}
//
// Content area
// ============================================================================

body > .main-container {
  overflow: hidden;
  position: relative;
  z-index: 1;
}
body.one-sidebar.sidebar-second {
  .region.region-content {
    @media (min-width: (@screen-lg)) {
      padding-right: 40px;
    }
  }
}

.main-container #content-wrapper .breadcrumb {
  display:none;
}


.how-to-fundraise {
  .main-wrapper {
    padding: 50px 15px 30px;
    margin: 0 auto;
    background-color: #F6F6F6;
    position: relative;

    &::before {
      content: '';
      background-color: #F6F6F6;
      display: block;
      width: 200vw;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: -50vw;
      margin-right: -50vw;
      height: 100%;
      z-index: -1;
    }
  }

  .fund-tips-block,
  .faq-block {
    background-color: #fff !important;
  }
}
