/*
 * Collaspe nav at tablet mode instead of at phone mode
 */
@media (max-width: (@screen-md - 1)) {
  .navbar-header {
    float: none;
  }
  .navbar-left,.navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
    margin-top: 7.5px;
  }
  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in{
    display:block !important;
  }
}

//
// Main Header
// ============================================================================

// There is a lot of code to be cleaned up over here :(
.main-header {
  border: 0 none;
  background-color: #ffffff;
  box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 0;
  position: relative;
  z-index: 2;
  border-radius: 0px;
}

.logo {
  position: relative;
  left: -1px;
  margin-right: 0;
  padding: 0 !important; // due to style on override.less
  max-width: 172px;
  margin-top: 0px;  //for supersmall devices only
  margin-bottom: 12px;

  @media only screen and (min-width : @screen-xs-min) {
    // margin-bottom: 8px;
    // margin-top: 8px;
  }

  @media (min-width: @screen-md-min) {
    max-width: 278px;
    margin-bottom: 15px;
  }
}

.header-block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -15px;

  @media (min-width: @screen-xs-min) {
    flex-wrap: nowrap;
  }
}

.navbar-logo {
  flex: 0 0 100px;

  position: absolute;
  width: 100px;

  @media (min-width: @screen-xs-min) {
    flex: 0 0 150px;
    position: relative;
    width: auto;
  }

  @media (min-width: @screen-md-min) {
    flex: 0 0 175px;
  }
}

.navbar-header {
  flex: 0 0  100%;

  padding: 0;
  float: none;

  @media (min-width: @screen-xs-min) {
    flex: 1 1 auto;
  }


  button.navbar-toggle {
    float: none;
    border: 0 none;
    margin: 1rem 1rem 0 auto;
    padding-right: 0;

    span.icon-bar {
      background-color: #000000;
      height: 5px;
      width: 26px;
      border-radius: 0;

      &.last{
        opacity: 0;
        height: 0;
        margin-top: 0;
      }

    }

    &:focus, &:hover {
      background-color: #fff;
    }

  }
}

#header-navigation {

  .main-menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: @screen-md-min) {
      padding: 1rem 0;
      flex-wrap: nowrap;
    }
  }

  .tagline {
    flex: 1 1 100%;
    align-self: center;
    order: 2;
    display: block;
    font-family: 'dinot-bold';
    font-style: italic;
    font-size: 14px;
    line-height: 1.2;
    color: #4A4A4A;
    margin: 1rem 10%;
    text-align: center;

    @media (min-width: @screen-xs-min) {
      flex: 0 1 160px;
      margin: 1rem;
      font-size: 12px;
      order: 0;
      text-align: left;
      padding-right: 1rem;
      border-right: 1px solid #D8D8D8;
    }

    @media (min-width: 550px) {
      flex-basis: 220px;
      font-size: 14px;
    }

    @media (min-width: @screen-md-min) {
      flex: 0 0 220px;
      font-size: 14px;
      margin: .5rem;
      order: 1;
      text-align: left;
      padding-right: .5rem;
      border-right: 1px solid #D8D8D8;
    }

    @media (min-width: @screen-lg-min) {
      font-size: 16px;
    }
  }

  nav.primary-nav-list {
    display: none;
    order: 3;
    padding: 0 20px;


    @media (min-width: @screen-md-min) {
      display: block;
      clear: none;
      width: 100%;

      background-color: #fff;
      border-top: 0 none;
      padding: 0 15px;
    }

    // .primary-nav-list {
    //   height: auto;
    //   text-align: center;
    //   position: relative;
    // }//primary-nav-list
  }//navbar-collapse
}//header-row-2

// -- Donate button --
.header-donate-btn {
  position: absolute;
  right: 50px;
  top: 5px;

  @media (min-width: @screen-xs) {
    top: 15px;
  }

  @media (min-width: @screen-sm-min) {

    .btn {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.2em;
      letter-spacing: 0;
      display: inline-block;

      &:hover {
        background: #007096;
        border-color: #007096;
      }
    }
  }

  @media (min-width: @screen-md) {
    position: static;
    right: auto;
    top: auto;
  }
}

//
// Region top nav
// ============================================================================

.top-menus {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-start;
}

.region-top-nav {
  flex: 1 1 auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-start;

  text-align: right;
  margin-top: 10px;

  @media (max-width: (@screen-md-min - 1)) {
    display: none;
  }


  #block-menu-menu-secondary-menu {
    flex: 1 1 auto;
  }
  #block-search-form {
    flex: 0 1 auto;
  }
}

.header-donate-btn {
  @media (max-width: (@screen-md-min - 1)) {
    // display: none;
  }
}

//
// Region top nav
// ============================================================================
.region-navigation {
  position: relative;
  display: block;
}


#jPanelMenu-menu {
  background: transparent;
}

.jPanelMenu {
  overflow-x: initial;
}

.jPanelMenu-panel {
  overflow: hidden;
}

[data-menu-position="open"] {
  #jPanelMenu-menu {
    background: #E4E7E8;
    border-left: 1px solid #D4D5D6;
    z-index: 99 !important;
  }
}
