
/**
 * Style only HTML tags
 */
 *,
 *::before,
 *::after {
   box-sizing: border-box; // 1
   box-sizing: border-box;
   text-rendering: optimizeLegibility;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   font-kerning: auto;
 }

 html {
   font-family: sans-serif; // 2
   line-height: 1.15; // 3
   -webkit-text-size-adjust: 100%; // 4
   -ms-text-size-adjust: 100%; // 4
   -ms-overflow-style: scrollbar; // 5
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // 6
   height: 100%;
   -webkit-overflow-scrolling: touch;
   -webkit-text-size-adjust: 100%;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }

 .jPanelMenu, body{
  overflow-x: visible !important;
}

 body {
  overflow-x: hidden !important;
  margin: 0 auto;
  .fz-responsive(20px, 1.25);
}

b,
strong {
  font-family: 'dinot-bold';
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
  font-family:'dinot-cond-bold';
}

h1,
h1.page-header {
  border: 0 none;
  .fz-responsive(56px, 0.9em);
  .underline-title;
  text-transform: none;
  margin: 62px auto 40px auto;
  padding: 0;
  text-transform: uppercase;

  &:after {
    margin: 30px auto 0;
  }
}

.page-node-messages,
.page-node-updates,
.node-type-project,
.page-node-supporters {

  #mini-panel-project_title_unlinked,
  #mini-panel-project_title {


    > .panel-panel {
      display: flex;
      flex-direction: column;

      > div {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
        border: 0 none;
        margin: 0 0 10px;
        padding: 10px 0;
        position: relative;


        @media (min-width: (@screen-sm-min)) {
          margin: 0 0 30px;
          padding: 20px 0;
        }

        @media (min-width: (@screen-md-min)) {
          margin: 0 0 30px;
          padding: 20px 0;
          height: auto;
          min-height: 190px;
        }

        &::before {
          content: '';
          background: rgba(240,240,240, 0.5);
          width: 200vw;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin-left: -50vw;
          margin-right: -50vw;
          height: 100%;
          z-index: -9;

          @media (min-width: (@screen-sm-min)) {
            background: #F0F0F0;
          }
        }

        .panel-separator {
          display: none;
        }

        > .panel-pane {
          width: 100%;
        }
      }

      .pane-node-title {
        display: block;
      }

      h1 {
        border: 0 none;
        .fz-responsive(46px, 1);
        // letter-spacing: 0.05em;
        text-align: left;
        position: relative;
        text-transform: none;
        margin: 0 0 10px;
        display: block;

        &::after {
          display: none;
        }
      }

      .field-name-field-fund-intro {
        font-size: .85em;
        display: block;
      }
    }
  }
}

.node-type-stories  {

  h1.page-header {
    .underline-title;
    margin: 62px 0 40px 0;
    text-align: left;
    border: 0 none;
    .fz-responsive(65px, 1);
    text-align: left;
    text-transform: none;

    &::after {
      height: 5px;
      background-color: #E8E8E8;
      margin-left: 0;
      margin-top: 30px;
    }
  }
}


h2 {
  .fz-responsive(@font-size-h2, 0.9em);
}

h3 {
  .fz-responsive(@font-size-h3, 0.9em);
}

a {
  outline: none;

  &:hover, &:focus {
    outline: none;
    text-decoration: none;
  }

  &.lnk-more {
    font-family: 'dinot-cond-bold';
    font-size: @font-size-small;
    font-weight: normal;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
}

// p { margin-bottom: 1.5em; }

img { max-width: 100%; }

table {

  td,
  th {
    border: 1px solid @gray;
  }
}

ul, ol {
  margin-bottom: 1.5em;
  padding-left: 1em;
}

ol li {
  margin-bottom: .5em;
}
ul li {
  list-style: none url(../images/red-dot.png) outside;
  margin-bottom: 1em;
}

hr {
  margin-top:    3em;
  margin-bottom: 3em;
}

p {
  margin: 0 0 1.5rem;
}

.lead {
  font-weight: 400;
  margin-bottom: 1.9rem;
  .fz-responsive(24px, 1.9);
}



blockquote {
  border: 0 none;
  color: @blue-medium;
  font-family: "Times New Roman", Georgia, serif;
  // .fz-responsive( 1.1em);
  font-size: 28px;
  line-height: 1.1em;

  @media (min-width: (@screen-sm-min)) {
    font-size: 32px;
  }

  font-style: italic;
  padding: 0 0 0 2em;
  position: relative;
  .col-sm-3 & {
    font-size: 26px;
  }

  &:before {
    content:"\201C";
    left: 0;
    position: absolute;
    top: 0.2em;
    font-size: 3em;
    font-weight: bold;
  }

  .b-footer {
    color: @gray;
    font-size: @font-size-small;
    font-family: "dinot", Arial;
    line-height: 1.42857143;
    font-style: normal;
    color: #54585A;
    padding-top: 10px;
    padding-left:0px;
  }
}
.b-footer {
  color: @gray;
  font-size: @font-size-small;
  font-family: "dinot", Arial;
  line-height: 1.42857143;
  font-style: normal;
  color: #54585A;
  padding-top: 10px;
  padding-left: 2em;
}
.custom-bloquote  {
  footer {
    p {
      font-size: 18px;
    }
  }
}

::-webkit-input-placeholder {
  text-align: left;
}

:-moz-placeholder { /* Firefox 18- */
  text-align: left;
}

::-moz-placeholder {  /* Firefox 19+ */
  text-align: left;
}

:-ms-input-placeholder {
  text-align: left;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


// Slick Slider

#block-views-banner-block {

  .slick--view--banner {
    position: relative;
  }

  nav.slick__arrow {
    .slick-arrow {
      -webkit-appearance: none;
      position: absolute;
      top: 0;
      height: 100%;
      width: 45px;
      background-color: rgba(0,112,150, 0.9);
      background-repeat: no-repeat;
      background-position: center center;
      text-indent: -9999px;
      border: 0;

    }
    .slick-prev {
      left: 0;
      background-image: url("../images/slick-prev.png");
    }
    .slick-next {
      right: 0;
      background-image: url("../images/slick-next.png");
    }
  } //nav.slick__arrow
} //#block-views-banner-block

.page-node-messages {

  .pane-node-comments {
    border-bottom: 7px solid #E8E8E8;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
  .pane-node-comment-form {
    padding: 3rem;
    background: #F0F0F0;
    color: #4A4A4A;

    h2.pane-title {
      text-align: center;
    }
  }
}


details.details_shim_closed,
details.details_shim_open {display: block;}
details.details_shim_closed > * {display: none;}
details.details_shim_closed > summary,
details.details_shim_open   > summary {display: block;}

.accordion {
  clear: both;
}


.accordion-item {

  details {
    padding: 5px 0;

    &.details_shim_open summary,
    &[open] summary,
    &[open=""] summary {
      outline:none;
      background-color: @red;
      color: #fff;
      background-image: url("../images/caret-white-up.svg");
      background-position: center right 20px;
      background-repeat: no-repeat;
    }

    .field-name-body,
    .field-name-field-body {
      padding: 20px 50px;
    }
  }
  summary {
    outline:none;
    background-color: #F0F0F0;
    border: 1px solid #E8E8E8;
    display: block;
    padding: 20px 60px 20px 20px;
    font-family: 'dinot-cond-bold';
    background-image: url("../images/caret-red.svg");
    background-position: center right 20px;
    background-repeat: no-repeat;

    color: @blue-medium;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: #F6F6F6;
      color: @red;
    }

    &::-webkit-details-marker {
      display:none;
    }

    .field-name-title {
      display: inline-block;

      h2 {
        .fz-responsive(26px);
        margin: 0;
      }
    }

  }
} //.accordion-item


.view-id-faq.view-display-id-page {
  .node-faq.view-mode-full {
    .accordion-item;
  }
}

.field-name-field-accordion {
  .media-asset-accordion {
    .accordion-item;
  }
}


label {
  font-family: 'dinot-cond-bold';
  text-transform: uppercase;
  font-size: 18px;
  font-weight: normal;
}
