//
// Typography
// ============================================================================
.fz-responsive(@fz, @lh) {
  font-size: @fz;
  line-height: @lh;
  @media (max-width: (@screen-md-min - 1)) { font-size: @fz * 0.6;}
  @media (max-width: (@screen-sm-min)) { font-size: @fz * 0.75;}

}

.fz-responsive(@fz) {
  font-size: @fz;
  @media (max-width: (@screen-md-min - 1)) { font-size: @fz * 0.6;}
  @media (max-width: (@screen-sm-min)) { font-size: @fz * 0.75;}

}

//
// Links
// ============================================================================
.color-link(@color, @color-hover) {
  color: @color;

  &:hover, &:focus { color: @color-hover; }
}

.color-link(@color) {
  color: @color;

  &:hover, &:focus { color: darken(@color, 15%); }
}

//
// Lists
// ============================================================================
.list-arrow {

  li { list-style-image: url(../images/red-right-arrow.png); }
}


.underline-title {
  margin: 0 auto 20px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'dinot-cond-bold';

   &:after {
    background: #4a4a4a;
    content: '';
    display: block;
    height: 1px;
    margin: 15px auto 0 auto;
    width: 100px;
  }
}


.underline-title-left {
  margin: 0 0 20px;
  text-align: left;
  text-transform: uppercase;
  font-family: 'dinot-cond-bold';

   &:after {
    background: #4a4a4a;
    content: '';
    display: block;
    height: 1px;
    margin: 15px 0 0 0;
    width: 100px;
  }
}
