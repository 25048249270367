//
// Landing Page
// ============================================================================
.node-landing-page {

  // -- View mode: Full
  &.view-mode-full {

    .field-name-node-link  {
      a {
        .btn();
        .btn-primary();
        .fz-responsive(23px);
      }
    }
    .field-name-title {

      h2 {
        .fz-responsive(@font-size-h1, 0.9em);
        letter-spacing: 0;
        margin: 25px auto 15px auto;
        text-transform: uppercase;

        a {
          .color-link(@blue-medium);
        }
      }
    }

    .field-name-body {
      margin-bottom: 25px;
    }
    .field-name-body {
      margin-bottom: 25px;
    }

    .field-name-field-content-referenced {

      > .field-items {
        .make-row();
        > .field-item {
          margin-bottom:15px;
          @media (max-width: (@screen-md-min - 1)) {
            margin-bottom: 46px;
          }
          .make-sm-column(6);
        }
      }
    }
  }
}

//
// Page with Slider
// ============================================================================
.node-basic-page-with-slider {

  // -- View mode: Full
  &.view-mode-full {

    > .field:first-child[class*="field-name-body"] {
      float: none;
      width: auto;
      img {
        padding: 0 20px;
      }
    }
  }
}

//
// Fund Type Taxonomy
// ============================================================================
.field-name-field-project-type {
  padding: .5rem 0;

  > .field-items {
    display: flex;
    flex-direction:row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    > .field-item {
      margin-right: .8rem;
    }
  }
}

.vocabulary-project-type {
  &.view-mode-icon {


    .group-layout {
      display: flex;
      flex-direction:row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      transition: all .5s;
      padding: 5px;
      color: @red;
      text-decoration: none;

      .field-name-field-icon {
        display: flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
        img {
          height: 28px;
          width: 28px;
        }
      }
      .field-name-title {
        display: none;
        font-family:'dinot-cond-bold';
        .fz-responsive(18px, 1);
        padding-left: .5rem;
      }

      &:hover {
        background: #F0F0F0;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
        border-radius: 5px;

        .field-name-title {
          display: block;
        }
      }
    }
  }
}//.vocabulary-project-type



//
// Giving Opportunites (Old Support Departments & Faculties)
// ============================================================================
.node-support-departments-faculties {

  // -- View mode: Teaser Hero
  &.view-mode-teaser_hero {

    .field-name-title {

      h2 {
        .fz-responsive(@font-size-h2, 0.9em);
        letter-spacing: 0;
        margin: 25px auto 15px auto;
        text-transform: uppercase;

        a {
          .color-link(@red);
        }
      }
    }

    .field-name-body {
      margin-bottom: 25px;
    }
  }

  // -- View mode: Teaser
  &.view-mode-teaser,
  &.view-mode-teaser_short {

    .field-name-title {

      h2 {
        .fz-responsive(26px);
        margin: 25px auto 15px auto;
        text-transform: uppercase;

        a { .color-link(@blue-medium); }
      }
    }

    .field-name-body {
      font-size: @font-size-small;
      // line-height: 1.3em;
      margin-bottom: 25px;
    }

    .field-name-field-department-or-faculty {
      font-size: @font-size-small - 2;
      font-weight: bold;
      // line-height: 1.3;
    }

    .field-type-taxonomy-term-reference {
      a {
        font-family:'dinot-cond-bold';
        font-weight: normal;
      }
    }
  }

  &.view-mode-teaser_wide {
    .make-row();

    .group-left {
      .make-xs-column(12);
      .make-sm-column(4);
    }
    .group-right {
      .make-xs-column(12);
      .make-sm-column(8);
    }

    .field-name-title {

      h2 {
        .fz-responsive(36px);
        margin: 0 0 15px;
        text-transform: uppercase;

        a { .color-link(@blue-medium); }
      }
    }

    .field-name-body {
      margin-bottom: 25px;
    }

    .field-name-node-link {
      a {
        .btn;
        .btn-primary;
      }
    }
  }

  // -- View mode: Full
  &.view-mode-full {

    .group-header {
      .make-xs-column(12);
      padding-bottom: 40px;
      margin-bottom: 10px;
      border-bottom: 7px solid #F0F0F0;
    }

    .field-name-field-accordion-title {
      .fz-responsive(44px);
      .underline-title;
      text-align: left;
      text-transform: uppercase;
      margin: 0 0 20px;
      line-height: 1;

      &:after {
        margin: 15px 0 0 0;
      }

    }

    .field-name-field-accordion-intro,
    .field-name-field-accordion {
      padding: 0 0 20px 0;
    }

    .group-related-stories {
      clear: both;
      position: relative;
      background-color: #F6F6F6;
      overflow-y: hidden;
      padding: 50px 0;
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;

      > h3 {
        .container-fluid;
        .fz-responsive(30px);
        margin-bottom: 55px;
        text-align: center;
        text-transform: uppercase;
        .underline-title;
      }
    }

    .field-name-field-related-fund {
      .make-xs-column(12);
    }

    .field-name-field-story-related-stories {
      .container-fluid;
      > .field-label { .make-xs-column(12); }

      > .field-items > .field-item {
        .make-md-column(4);
        margin-bottom: 40px;
      }
    }



    > .field,
    > .field-group-div {
      margin-bottom: 25px;
    }

    // .field-group-div > .field { margin-bottom: 30px; }

    // .field-name-field-video + .field-name-field-slideshow { display: none; }

    // .field-name-field-donate-now-link {
    //   text-align: center;

    //   a {
    //     .btn();
    //     .btn-primary();
    //     .btn-lg();
    //   }
    // }

    // .field-name-field-facebook-share-link,
    // .field-name-field-twitter-share-link {
    //   font-size: 19px;
    //   padding-left: 0;
    //   margin-bottom: 20px;

    //   a {
    //     background: url('../images/icon_facebook.png') no-repeat scroll 0 0;
    //     .color-link(@gray);
    //     font-family: 'dinot';
    //     display: inline-block;
    //     padding-left: 65px;
    //     padding-bottom: 20px;
    //     padding-top: 15px;
    //   }
    // }

    // .field-name-field-twitter-share-link {
    //   padding-right: 0;

    //   a { background: url('../images/icon_twitter.png') no-repeat scroll 0 0; }
    // }

    // @media (min-width: @screen-md-min) {
    //   .field-name-field-video,
    //   .field-name-field-slideshow,
    //   .group-social-media  {
    //     clear: right;
    //     float: right;
    //   }
    // }

  }
}


//
// Giving Opportunites (Old Support Departments & Faculties)
// ============================================================================
.node-project,
.node-type-project {

  .pane-node-body {
    .field-name-body {
      .fz-responsive(20px, 1.5);
    }
  }


  // -- View mode: Teaser
  &.view-mode-teaser,
  &.view-mode-teaser_short {

    .field-name-title {

      h2 {
        .fz-responsive(26px);
        margin: 25px auto 15px auto;
        text-transform: uppercase;

        a { .color-link(@blue-medium); }
      }
    }

    .field-name-body {
      margin-bottom: 25px;
    }

    .field-name-field-department-or-faculty {
      font-size: @font-size-small - 2;
      font-weight: bold;
      // line-height: 1.3;
    }

    .field-type-taxonomy-term-reference {
      a {
        font-family:'dinot-cond-bold';
        font-weight: normal;
      }
    }
  }

  &.view-mode-teaser_wide {
    .make-row();
    padding-bottom: 70px;

    .group-left {
      .make-sm-column(4);
    }
    .group-right {
      .make-sm-column(8);
    }

    .field-name-title {

      h2 {
        .fz-responsive(26px);
        margin: 0 0 15px;
        text-transform: uppercase;

        a { .color-link(@blue-medium); }
      }
    }

    .field-name-body {
      margin-bottom: 25px;
    }

    .field-name-node-link {
      a {
        .btn;
        .btn-primary;
      }
    }
  }
}


//
// Giving Vehicle
// ============================================================================
.node-giving-vehicles {

  // -- View mode: Teaser Hero
  &.view-mode-teaser_hero {
    margin-bottom: 20px;

    .field-name-node-link  {
      a {
        .btn();
        .btn-primary();
        font-size: 23px;
      }
    }
    .field-name-title {

      h2 {
        .fz-responsive(@font-size-h1, 0.9em);
        letter-spacing: 0;
        margin: 25px auto 15px auto;
        text-transform: uppercase;

        a {
          .color-link(@blue-medium);
        }
      }
    }

    .field-name-body {
      margin-bottom: 25px;
    }
  }

  // -- View mode: Full
  &.view-mode-full {

    > .field,
    > .field-group-div {
      margin-bottom: 25px;
    }
  }
}


//
// Staff
// ============================================================================
.node-staff-directory {

  // -- View mode: Teaser
  &.view-mode-teaser,
  &.view-mode-teaser_wide {
    font-size: 16px;
    .make-row();

    .field-name-field-staff-image {
      margin-bottom: 10px;
    }

    .group-names {
      font-family: 'dinot-cond-bold';
      .fz-responsive(28px);

      .field {
        &.field-name-title {
          h2 {
            margin-top: 0px;
            line-height:1.1em;
          }
        }
      }
    }
    .field-name-title h2 {
      font-family: 'dinot-bold';
      font-size: 18px;
      margin: 0;
    }
    .field-name-field-job-title-textarea {
      display: block;
      margin-bottom: 10px;
    }
  }

  &.view-mode-teaser_wide {
    display: flex;
    flex-direction:row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 100%;
    margin: 0;

    .group-left {
      float: none;
      width: 115px;
      flex: 0 0 115px;
      padding-right: 15px;
    }
    .group-right {
      float: none;
      width: auto;
      flex: 1 1 auto;
      font-size: .9em;
       /* These are technically the same, but use both */
      overflow-wrap: break-word;
      word-wrap: break-word;

      -ms-word-break: break-all;
      /* This is the dangerous one in WebKit, as it breaks things wherever */
      word-break: break-all;
      /* Instead use this non-standard one: */
      word-break: break-word;

      @media (min-width: (@screen-md-min)) {
        font-size: 1em;
      }
    }
  }
}

//
// Stories
// ============================================================================
.node-stories {

  // -- View mode: Teaser Hero
  &.view-mode-teaser_hero {

    .field-name-title {

      h2 {
        .fz-responsive(@font-size-h1, 0.9em);
        letter-spacing: 0;
        margin: 25px auto 15px auto;
        text-transform: uppercase;

        a {
          .color-link(@blue-medium);
        }
      }
    }

    .field-name-body {
      margin-bottom: 25px;
    }
  }

  // -- View mode: Teaser
  &.view-mode-teaser,
  &.view-mode-teaser_short {

    .field-name-node-link {
      margin-bottom: 30px;
    }
    .field-name-title {

      h2 {
        .fz-responsive(26px);
        margin: 25px auto 15px auto;

        a { .color-link(@blue-medium); }
      }
    }

    .field-name-body {
      .fz-responsive(20px, 1.25);
      margin-bottom: 25px;
    }

    .field-name-node-link a {
      font-family: 'dinot-cond-bold';
      font-size: @font-size-small;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }

  // -- View mode: Full
  &.view-mode-full {

    > .field,
    > .field-group-div {
      // margin-bottom: 50px;
    }

    .field-group-div > .field { margin-bottom: 30px; }
    > .field-name-body {
      .fz-responsive(22px, 1.5);
      margin-bottom: 40px;
      .make-md-column(12);

      @media (min-width: (@screen-md)) {
        float: none;
      }


    }
    .field-name-field-video + .field-name-field-slideshow { display: none; }

    .field-name-field-subtitle {
      .fz-responsive(26px);
      padding-bottom: 20px;
    }
    .field-name-field-video,
    .field-name-field-slideshow {
      float: right;
    }
    .field-slideshow-slide {
      .field-slideshow-caption {
        padding-top: 18px;

        .field-slideshow-caption-text {
          font-style: italic;
          .fz-responsive(17px, 1.25);
          color: #4A4A4A
        }
      }
    }

    .group-related-stories {
      clear: both;
      position: relative;
      background-color: #F6F6F6;
      overflow-y: hidden;
      padding: 50px 0;
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;

      > h3 {
        .container-fluid;
        .fz-responsive(30px);
        margin-bottom: 55px;
        text-align: center;
        text-transform: uppercase;
        .underline-title;
      }
    }

    .field-name-field-related-fund {
      .make-xs-column(12);
    }

    .field-name-field-story-related-stories {
      .container-fluid;
      > .field-label { .make-xs-column(12); }

      > .field-items > .field-item {
        .make-md-column(4);
        margin-bottom: 40px;
      }
    }

    @media (min-width: @screen-md-min) {
      .field-name-field-video,
      .field-name-field-slideshow,
      .group-social-media  {
        clear: right;
        float: right;
      }
    }
  } // &.view-mode-full
}


//
// page-faculties-and-departments
// ============================================================================
.page-faculties-and-departments {
  .view-display-id-attachment_1,
  .view-display-id-attachment_2
  {
    display:none
  }
}


//
// General overides
// ============================================================================
.field-name-field-slide-caption {
  padding-top: 18px;

  .field-item {
    font-style: italic;
    font-size: 18px;
  }
}
.field-collection-container {
  border-bottom:0px !important;
}

//
// node-basic-page-with-slider
// ============================================================================

.node-basic-page-with-slider{
  &.view-mode-full {

    .field-slideshow-wrapper {
      margin-bottom: 1em;
    }
  }
}

//
// Webforms
// ============================================================================
.node-webform {

  &.view-mode-full {

    > .webform-client-form { margin: 15px 0 30px 0; }
  }
}

.pane-node-field-project-updates,
.pane-donation-feed-panel-pane-2 {
  border-bottom: 7px solid #E8E8E8;
  margin-bottom: 40px;
}
.donation-updates {
  margin-bottom: 40px;


  .field-name-field-date {
    font-family:'dinot-cond';
    letter-spacing: 1.1px;
    text-transform: uppercase;
    .fz-responsive(18px, 1.1);
  }
  .field-name-title h3 {
    .fz-responsive(24px, 1.1);
  }

  .field-name-field-body {
    &:after {
      background: #4a4a4a;
      content: '';
      display: block;
      height: 1px;
      margin: 40px 0 0px 0;
      width: 100px;
    }
  }
}


.comment {
  margin: 0 0 50px;

  > span {
    font-family:'dinot-cond';
    letter-spacing: 1.1px;
    text-transform: uppercase;
    .fz-responsive(18px, 1.1);
    margin-bottom: 20px;
    display: block;

    .username {
      color: #4A4A4A;
      font-family:'dinot-cond-bold';
    }
  }
  .field-name-post-date {
    font-family:'dinot-cond';
    letter-spacing: 1.1px;
    text-transform: uppercase;
    .fz-responsive(18px, 1.1);
  }

  .field-name-comment-body {
    &:after {
      background: #4a4a4a;
      content: '';
      display: block;
      height: 1px;
      margin: 40px 0 0px 0;
      width: 100px;
    }
  }
}


.page-taxonomy-term {
  #block-system-main {
    .make-row();

    .term-listing-heading {
      .make-xs-column(12);
      padding-bottom: 60px;
    }

    // .node-project,
    // .node-support-departments-faculties {
    //   &.node-teaser {
    //     .make-xs-column(12);
    //     .make-sm-column(6);
    //     .make-md-column(4);
    //     .make-lg-column(3);
    //     padding-bottom: 30px;
    //   }
    // }

  }
}


.node-type-fundraising-form {

  .field-name-field-form-title {
    .fz-responsive(44px, 1);
    .underline-title;
    text-align: left;
    text-transform: uppercase;
    margin: 0 0 20px;

    &:after {
      margin: 15px 0 0 0;
    }
  }
  .field-name-field-form-instructions {
    padding: 0 0 20px 0;
  }

  #block-system-main {
    .form-control {
      display: block;
      width: 100%;
      // height: 54px; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
      padding: 18px 30px;
      font-size: 18px;
      line-height: @line-height-base;
      color: @input-color;
      background-color: @input-bg;
      background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
      border: 1px solid @input-border;
      border-radius: 0;
      box-shadow: none;
      .transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");

      // Customize the `:focus` state to imitate native WebKit styles.
      .form-control-focus();

      // Placeholder
      .placeholder();

      // Disabled and read-only inputs
      //
      // HTML5 says that controls under a fieldset > legend:first-child won't be
      // disabled if the fieldset is disabled. Due to implementation difficulty, we
      // don't honor that edge case; we style them as disabled anyway.
      &[disabled],
      &[readonly],
      fieldset[disabled] & {
        background-color: @input-bg-disabled;
        opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
      }

      &[disabled],
      fieldset[disabled] & {
        cursor: @cursor-disabled;
      }

      // Reset height for `textarea`s
      textarea& {
        height: auto;
      }
    }
  }



}
