.btn {
  font-family: 'dinot-cond-bold';
  font-size: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.btn-info,
.btn-default,
.btn-primary {

  &:focus,
  &.focus {
    color: #fff;
    background-color: @blue-medium;
        border-color: @blue-medium;
  }
  &:hover {
    color: #fff;
    background-color: @blue-medium;
        border-color: @blue-medium;
  }
}


.btn-sm { font-size: floor(18px * 0.75); }
.btn-lg { font-size: ceil(18px * 1.25); }


.more-link {
  text-align: center;
  a {
    .btn;
    .btn-primary;
  }
}


a.readmore-link  {
  margin: 40px auto;
  display: block;
  padding: 10px 0 10px 0;
  font-family: 'dinot-cond-bold';
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  border-bottom: 7px solid #E8E8E8;

  &:after {
    content: "";
    background: url('../images/caret-red.svg') center center no-repeat;
    display: inline-block;
    vertical-align: middle;
    margin: -4px 0 0 10px;
    height: 20px;
    width: 30px;
  }
}

.pager-load-more {
  list-style: none;
  padding: 0;
  margin: 0;

  .pager-next {
    list-style: none;
    padding: 0;
    margin: 0;


    a {
      border: 0;
      border-radius: 0;
      margin: 40px auto 0;
      display: block;
      padding: 10px 0 10px 0;
      font-family: 'dinot-cond-bold';
      font-size: 24px;
      text-transform: uppercase;
      text-align: center;
      // border-bottom: 7px solid #E8E8E8;

      &:after {
        content: "";
        background: url('../images/caret-red.svg') center center no-repeat;
        display: inline-block;
        vertical-align: middle;
        margin: -4px 0 0 10px;
        height: 20px;
        width: 30px;
      }
    }
  }
}

a.see-all {
  margin: 20px auto;
  display: block;
  padding: 20px 0 10px 0;
  font-family: 'dinot-cond-bold';
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  border-top: 1px solid #4A4A4A;

  &:after {
    content: "";
    background: url('../images/caret-red.svg') center center no-repeat;
    display: inline-block;
    vertical-align: middle;
    margin: -4px 0 0 10px;
    height: 20px;
    width: 30px;
  }

}
