footer.footer {
  background-color: #e4e7e8;
  margin: 0;
  padding: 20px 20px 80px;

  a { .color-link(@blue-medium); }


  #copyright-terms {
    font-size: 13px;

    a {
      color: @text-color;
    }
  }

  a.sfu {
    background: url('../images/SFU_logo_footer.png') no-repeat;
    background-size: cover;
    display: block;
    height: 38px;
    overflow: hidden;
    text-indent: 9999px;
    width: 76px;
  }

  .block-webform {
    h2.block-title {
      margin-top: 0;
    }
  }

  form.webform-client-form {
    > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      .form-item {
        flex: 1 1 auto;
        display: block;
        width: 100%;

        .form-control {
          width: 100%;
        }
      }

      .form-actions {
        flex: 0 0 112px;
        display: block;
        width: 100%;

      }
    }
  }


  .footer-acknowledgement {
    font-size: 16px;
    margin: 30px 0;
  }

} //footer.footer
.region-footer {
  .make-xs-column(12);
  .make-md-column(4);
}

.region-footer-nav {
  .make-xs-column(12);
  .make-md-column(8);
}

.page-stories {
  footer {
    margin: 30px 0 0 0 !important;

  }
}

.node-type-stories {
  footer {
    margin: 0px !important;

  }
}

#mobile-menu {
  display: none;
}
